import React from 'react';
import Layout from '../../staticcomponents/layout';
import RequestFormPopup from '../../staticcomponents/RequestFormPopup';
import './campaign.scss';


export default function Campaign(){
    return (
        <>
        <Layout>
            <div className='campaign-cls '>
            <h1>Request form</h1>
        <RequestFormPopup/>
        </div>
        </Layout>
        </>
    )
}